<template>
  <div class="app-container">
    <div class="container">
      <div class="center"><h2><b>海尔智能电视个人信息保护政策</b></h2></div>
<p><b>感谢您的使用！</b></p>
<p><b>本协议中的“您”指任何使用或访问本服务的个人。
        <br>请仔细阅读本《海尔智能电视个人信息保护政策》（以下简称“本协议”）。若您使用本服务，则视为您已与我们达成有法律约束力的协议。若您不同意本协议，请勿使用本服务。</b>
      </p>
<p>本协议适用的产品范围为海尔智能电视，统帅智能电视，卡萨帝智能电视，每个系列电视的具体应用和服务有所不同。由于我们的产品和服务较多，为您提供的产品和服务内容也有所不同，本协议为智能电视都适用的条款，本政策所述以及我们收集的您个人信息的类型可能会因您使用的具体的产品/服务（包括客户端类型、软件版本等）而有所不同，<b>具体以您实际使用的产品/服务的实际情况为准。若您未使用我们提供的相应的产品或服务，则我们不会收集相对应的信息，相应的条款则对您不适用。</b></p>
<p><b>一、	我们如何收集信息</b></p>
<p>
        1、我们可能会收集的信息
        <br>
        为使您更便捷或更有乐趣的使用我们的产品和/或服务，从而提升您的使用体验，我们的以下附加功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用我们的产品和/或服务，但您可能无法使用这些附加功能。这些附加功能包括如下内容以及其他您在使用我们的电视产品或智家app中您确认提供的权限及信息：
        <br><br>
        基于WIFI的服务：我们会获取您的WIFI账号及密码进行联网，不联网登录则只能使用电视基本功能，影音娱乐、智慧学堂、语音助手、天气预报、家联网、AI运动、AI学习、手势控制以及海尔账号等依赖网络服务的功能无法使用。
        <br><br>
        基于位置信息的服务：您的位置信息将被用于推荐天气和用于售后运维。
        <br><br>
        基于摄像头（外置）的附加服务：外接摄像头仅会在您使用相关服务时开启。请您知晓，即使您已同意开启摄像头权限，我们也仅会在您主动点击相关服务时使用，并且仅用于该服务期间，退出服务后会立即关闭。请您知悉，部分基于摄像头的功能，比如儿童安全守护功能，在服务使用期间会抓拍并存储摄像头前的画面，便于您及时查看儿童状况。如果您介意，可以选择不使用该类服务。一旦您开启该类功能，则默认您知晓并同意该等授权。
        <br><br>
        基于语音技术相关附加服务：您可在遥控器对码后使用遥控器语音功能。部分具备远场语音功能的机型，在您主动联网开机后，则默认同意远场语音功能即开启。
        <br><br>
        基于电话的附加功能：您可以在打开家联网/家立方后实现拨打电话的功能，但不会访问您的电话联系人。
        <br><br>
        基于设备的附加功能：为保障您的账号与使用安全，您需要授权我们读取本机识别码。
        <br><br>
        基于蓝牙的附加功能：你可以在打开蓝牙权限或通过蓝牙连接您的其他海尔产品，如体脂秤、血压计等。
        <br><br>
        食材管理：你输入的食材数据，比如种类、储存期限等会在我们云端储存。
        <br><br>
        影音功能：我们会收集您的浏览历史记录。
        <br><br>
        基于面容的附加功能（部分型号提供）：您可以在您的设备上提前录入面容ID信息，并在使用服务时在您的设备上完成【人脸识别】。我们需要使用您的面容权限以便接收验证结果，但我们并不收集您的面容信息，面容信息存储在电视本地，不会上传云端。若您不开启此权限，您将无法使用人脸识别服务。
        <br><br>
        商城及店铺的购买功能：如果您选择点击进入电视上的商城，并在上面进行购买，当您准备对您购物车内的商品进行结算时，系统会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式。
        <br><br>
        您可以在您的产品中逐项查看您上述权限的开启状态，并可以决定将这些权限随时开启或关闭。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
        <br><br>
      </p>
<p><b>二、 我们如何使用信息</b></p>
<p>
        <br>
        我们会根据本协议的约定对所收集的个人信息进行使用。
        <br><br>
        在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，并经分析、统计、加工后形成不含身份识别内容的信息、报告等（以下简称“匿名汇总统计数据”）。
        <br><br>
        请您注意，您在使用我们的产品和/或服务时所提供的所有个人信息，将在您使用我们的产品和/或服务期间持续授权我们使用，除非您删除或通过系统设置拒绝我们收集。在您注销账号时，我们将停止使用并删除您的个人信息。
        <br><br>
        当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理等方式对您的信息进行脱敏，以保护您的信息安全。
        <br><br>
        当要将您的个人信息用于本政策未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，我们会及时通知您并通过您主动做出确认的形式事先征求您的同意。
        <br><br>
        您的个人信息将全部被存储于中华人民共和国境内，如需跨境传输，我们将会单独征得您的授权同意。
        <br><br>
      </p>
<p><b>三、 第三方服务</b></p>
<p>
        <br>
        我们产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息，涉及嵌入第三方代码、插件传输个人信息的情形。
        <br><br>
        前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。但我们也会努力审查该第三方的业务准入资质并努力要求该等服务商的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。
        <br><br>
        <b>您同意，海尔不对任何第三方服务的内容或可用性负责，也不为第三方在本服务中提供的任何内容、广告、服务或其它信息作出任何形式的背书。海尔不为第三方软件或技术提供任何客户支持。若您需要支持，需联系第三方服务提供商各第三方服务提供商为其服务及其内容及所作保证承担全部责任。</b>
        <br><br>
      </p>
<p><b>四、您的权利</b></p>
<ol>
        <li>
          您有权访问您的个人信息。如果您想行使数据访问权，可以通过以下方式自行访问：
          <ol>
            <li>账户信息：如果您希望访问或编辑您的账户中的个人资料信息及您家人的健康信息，您可以登录您的账户后通过访问设置页面执行此类操作。</li>
            <li>其他个人信息：对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供，请通过本协议列明的联系方式与我们取得联系。</li>
          </ol>
        </li>
        <li>
          更正您的个人信息
          <br><br>
          当您发现我们处理的关于您的个人基本信息（例如年龄、身高、体重）有错误时，您有权自行或要求我们做出更正。对于账户信息，您可以登录您的账户后通过访问设置页面执行此类操作。您自动录入的数据可以修改，对于您设备自动采集的信息，则无法更正，只能通过设备自动采集覆盖之前的信息。 
        </li>
        <li>
          删除您的个人信息
          <br><br>
          您的个人信息可以在个人信息页面手动删除。<b>您也可以自行在家人板块删除某个虚拟的家人账户，删除的同时，该家人账户下的信息也将删除。</b>
          <br><br>
          在以下情形中，您可以向我们提出删除个人信息的请求：
          <br>
          (1)	如果我们处理个人信息的行为违反法律法规；
          <br><br>
          (2)	如果我们收集、使用您的个人信息，却未征得您的同意；
          <br><br>
          (3)	如果我们处理个人信息的行为违反了与您的约定；
          <br><br>
          (4)	如果您注销了账号；
          <br><br>
          (5)	如果我们不再为您提供产品和服务。
          <br><br>
          <b><u>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</u></b>
        </li>
        <li>
          改变您授权同意的范围
          <br><br>
          您可以通过提交请求，撤销授权我们收集、使用和/或披露我们掌握或控制的您的个人信息的许可。对于不能够在电视上由您自行点击设置的权限，您可以通过联系我们进行相关操作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。
          <b><u>请注意，由于每个业务场景需要一些基本的个人信息才能得以完成，您撤销同意可能导致部分服务不可用。当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</u></b>
        </li>
        <li>
          <b><u>我们通常免费提供访问、更正、更新、删除服务，但是保留对您的数据访问请求收取合理费用的权利。</u></b>
        </li>
        <li>
          若您想要注销账户，需要在智家app上进行申请，并遵守相应的提示和流程。若您注销账户，您账号下的家人信息也会相应删除，无法找回，请知悉。
        </li>
      </ol>
<p><b>五、其他</b></p>
<p>
        本协议同样遵守《海尔智家个人信息保护政策》，关于本服务涉及个人信息的其他隐私权政策的内容，请参考《海尔智家个人信息保护政策》。
        <br><br>
        如果您对我们的服务有任何需求、投诉或建议，请参见《海尔智家个人信息保护政策》中的联系方式。
      </p>
    </div>
    <div class="box">
      <button class="know" @click="back">
        我知道了
      </button>
    </div>
  </div>
  
  
</template>
<script>
export default {
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .container {
    padding: 24px 40px;
    margin-bottom: 180px;
  }
  .box {
    width: 100%;
    height: 180px;
    background: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .know{
      width: 495px;
      height: 78px;
      background: linear-gradient(155deg, #397EF5 0%, #95A4FF 100%);
      border-radius: 39px;
      border: none;
      color: #fff;
      font-size: 30px;
    }
  }
}
</style>