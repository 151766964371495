import { createApp } from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible'
import './index.scss'
import 'vant/lib/index.css';
import router from './router';
import { Button, Toast, Radio, Loading } from 'vant'

const app = createApp(App)
app.use(router)
app.mount('#app')
app.use(Button)
app.use(Toast)
app.use(Radio)
app.use(Loading)

