// import Vue from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'

// Vue.use(Router)  
import Home from '@/components/HelloWorld'
import Mobile from '@/components/mobileLogin'
import Protocal from '@/components/protocalInfo'
import Protocal2 from '@/components/protocalInfo2'
import Result from '@/components/resultInfo'

export const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/mobileLogin",
    component: Mobile
  },
  {
    path: "/protocalInfo",
    component: Protocal
  },
  {
    path: "/protocalInfo2",
    component: Protocal2
  },
  {
    path: "/result",
    component: Result
  }
]

const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})
export default router