import axios from "axios";

const service = axios.create({
  baseURL: '/hm',
  timeout: 5000
}) 

service.interceptors.request.use(
  config => {
    const deviceInfo = JSON.parse(sessionStorage.getItem('deviceInfo'))
    // config.headers['Authorization'] = 'Token ' + getToken()
    if (deviceInfo){
      config.headers['DeviceMAC'] = deviceInfo.deviceMAC
      config.headers['WiredMAC'] = deviceInfo.wiredMAC
    }

    return config
  },
  error => {
    console.log('拦截错误', error)
  }
)
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // 如果请求状态不是200，返回失败状态 
    if (response.status !== 200) {
      console.log('错误1')
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    console.log(service.url)
    // const response = error.response;
    console.log('错误2', error)
    // return Promise.reject(error.response.data)
  }
)
export function getTestUrl() {
  return service({
    url: "/app/account/wechat/qrWechatLoginTest?deviceMAC=c4:98:5c:fd:1f:ec&wiredMAC=B0:A3:7E:FB:22:91&uhome_client_id=35B93E27841F8A01F5A3E3734BC209B3&uhome_app_version=1&url=http%3A%2F%2Fwww.baidu.com",
    method: 'get'
  })
}
// 获取微信授权URL
export function wechatUrl(params) {
  return service({
    url: "/app/account/wechat/qrWechatAuthorizeUrl",
    method: 'get',
    params
  })
}
// 获取验证码
export function sendVerifyCode(data) {
  return service({
    url: "/app/account/wechat/sendVerifyCode",
    method: 'POST',
    data
  })
}
// 登录
export function mobileLogin(data) {
  return service({
    url: "/app/account/wechat/login",
    method: 'POST',
    data
  })
}
// 判断微信有没有授权
export function wechatOauth(params) {
  return service({
    url: "/app/account/wechat/oauth",
    method: 'GET',
    params
  })
}
// 绑定手机号
export function socailBind(data) {
  return service({
    url: "/app/account/wechat/socialBind",
    method: 'POST',
    data
  })
}