<template>
  <div class="container">
    <img class="resultimg" src="./../assets/result.png" alt="">
    <div class="result">恭喜您，登录成功</div>
  </div>
</template>
<script>
export default{
  created() {
    window.TDAPP.onEvent('登录成功')
    sessionStorage.clear()
  },
  beforeRouteLeave(to, from, next) {
    console.log(to, from, next)
    this.closeWXWindow()
  },
  methods: {
    closeWXWindow() {
      // 判断苹果手机内置浏览器
      var ua = navigator.userAgent.toLowerCase();
      if(window.WeixinJSBridge) {
        //这个可以关闭安卓系统的手机
        document.addEventListener("WeixinJSBridgeReady", function() {
          window.WeixinJSBridge.call("closeWindow");
        }, false);
        //这个可以关闭ios系统的手机
        window.WeixinJSBridge.call("closeWindow")
      } else if(navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
        window.location.href = "about:blank";
        window.opener = null;
        window.open('about:blank', '_self', '').close();
        window.close();
    
      } else if(ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
        ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
        window.location.href = "about:blank";
        window.opener = null;
        window.open('about:blank', '_self', '').close();
        window.close();
    
      } else {
        window.close();
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
  .resultimg {
    width: 190px;
    margin-top: 84px;
    margin-bottom: 60px;
  }
  .result {
    font-size: 40px;
    color: #333;
  }
}
</style>