<template>
  <div class="login">
    <div v-if="isLoading" class="loadingbox">
      <van-loading type="spinner" color="#1989fa" />
    </div>   
    <div class="top"></div>
    <!-- <van-button type="primary">测试</van-button> -->
    <div class="btn">
      <img class="loginbtn" src="./../assets/login1.png" @click="login" alt="">
    </div>
    <div class="agreements">
      <img v-if="sure===false" src="./../assets/normal.png" alt="" @click="editSure(true)">
      <img v-if="sure===true" src="./../assets/select.png" @click="editSure(false)">
      <!-- <input type="radio" v-model="sure" :checked="sure" @click="editSure"> -->
      <span class="info">已阅读并同意 <span class="agreement" @click="toProtocal">《海尔智能电视服务条款》</span><span class="agreement" @click="toProtocal2">《海尔电视个人信息保护政策》</span> </span>
    </div>
    <div class="foot">
      <div class="subtit">其他登录方式</div>
      <img class="mobile" src="./../assets/mobile.png" @click="mobileClick">
    </div>
  </div>
</template>

<script>
import { wechatUrl, wechatOauth } from '@/API/request'
import { getUrlParams } from '@/utils'
import { Toast } from 'vant'
// import VConsole from 'vconsole'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      sure: false,
      mobile: "",
      wechatUrl: '',
      isLoading: false,
      wechatCallBackParams: {}
    }
  },
  created() {
    // new VConsole();
    const ua = navigator.userAgent.toLowerCase();
    sessionStorage.removeItem('mobile')
    sessionStorage.removeItem('verifyCode')
    if (ua.match(/MicroMessenger/i) == 'micromessenger'){
      this.getTestURL()
    } else {
      // 普通浏览器直接跳转到手机号登录
      let href = window.location.href
      href = href.substring(0, href.length-2)
      const hrefObj = getUrlParams(href)
      sessionStorage.setItem('deviceInfo',JSON.stringify(hrefObj))
      this.$router.push({ path: "/mobileLogin", query: {type: '1'}})
    }
    
  },
  methods: {
    async getTestURL() {
      let href = window.location.href
      href = href.substring(0, href.length-2)
      const hrefObj = getUrlParams(href)
      console.log('地址参数', hrefObj);
      if (href.indexOf('uuid') === -1) {
        // 微信回调后判断是否绑定
        // 获取地址上的参数
        this.wechatCallBackParams = getUrlParams(href)
        this.wechatCallBackParams.uuid = JSON.parse(sessionStorage.getItem('deviceInfo')).uuid;
        // this.wechatCallBackParams.machineCode = JSON.parse(sessionStorage.getItem('deviceInfo')).machineCode;
        if (sessionStorage.getItem('isWechatLogin') === 'true') {
          sessionStorage.setItem('isWechatLogin','false')
          this.sure = true
          this.isLoading = true
          if (hrefObj.code && hrefObj.state) {
            this.wechatOauth()
          } else {
            this.isLoading = false
            Toast('微信授权失败，请重新扫码登录')
          }
        }
      } else {
        sessionStorage.setItem('deviceInfo',JSON.stringify(hrefObj))
        this.wechatUrl = sessionStorage.getItem('wechatUrl')
        console.log('获取微信登录地址参数', hrefObj);
        if(!this.wechatUrl) {
          wechatUrl(hrefObj).then(res => {
            if (res.errcode === 0) {
              this.wechatUrl = res.data.url
              sessionStorage.setItem('wechatUrl', this.wechatUrl)
            } else if(res.errcode === 1005){
              Toast('二维码过期, 请刷新二维码')
            } else {
              Toast(res.errmsg)
            }
          })
        }
      }
    },
    wechatOauth() {
      console.log('微信登录回调后，wechatOauth参数', this.wechatCallBackParams);
      wechatOauth(this.wechatCallBackParams).then(res => {
        this.isLoading = false
        sessionStorage.setItem('wechatInfo', JSON.stringify(res.data))
        if (res.errcode === 0){
          sessionStorage.setItem('bind', res.data.bind)
          if (res.data.bind) {
            this.$router.push('/result')
          } else {
            Toast('请绑定手机号')
            this.$router.push({ path: "/mobileLogin", query: {type: '2'}})
          }
        } else {
          Toast(res.errmsg)
        }
      })
    },
    editSure(val) {
      this.sure = val
    },
    login() {
      window.TDAPP.onEvent('微信一键登录')
      if (this.sure) {      
        const bind = sessionStorage.getItem('bind')
        if (bind === 'true') {
          this.$router.push('/result')
        } else if (bind === 'false') {
          Toast('请绑定手机号')
            this.$router.push({ path: "/mobileLogin", query: {type: '2'}})
        } else {
          sessionStorage.setItem('isWechatLogin', 'true')
          console.log('一键登录地址', this.wechatUrl);
          if (this.wechatUrl) {
            this.isLoading = true
            window.location.replace(this.wechatUrl)
          } else {
            Toast('请重新扫码')
          }
        }
      } else {
        Toast('请阅读并同意服务条款')
      }
    },
    mobileClick() {
      window.TDAPP.onEvent('点击手机号登录')
      this.$router.push({ path: "/mobileLogin", query: {type: '1'}})
    },
    toProtocal() {
      window.TDAPP.onEvent('查看服务条款')
      this.$router.push('/protocalInfo')
    },
    toProtocal2() {
      window.TDAPP.onEvent('查看信息政策')
      this.$router.push('/protocalInfo2')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .login{
   background: linear-gradient(360deg, #EAECFE 0%, #FFFFFF 100%);
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   .loadingbox {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
   }
    .top{
      width: 100%;
      height: 456px;
      background-image: url('./../assets/bg.png');
      background-position: center center;
      background-size: cover;
    }
    .btn {
      width: 100%;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      .loginbtn{
        width: 70%;
        height: 80px;
      }
    }
    .agreements{
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      width: 73%;
      margin-top: 60px;
      display: flex;
      align-items: flex-start;
      img {
        width: 22px;
        height: 22px;
        margin-top: 5px ;
        margin-right: 5px;
      }
      input {
        // margin: 2px 2px 0 0;
        width: 30px;
        height: 30px;
        border: #707070;
      }
      // input[checked="sure"] {
      //     border-color: #4784F7;
      //     background: #4784F7;
      // }

      .info{
        text-align: left;
        font-size: 24px;
      }
      .agreement {
        color: #397EF5;
      }
    }
    .foot {
       position: absolute;
       bottom: 40px ;
       width: 100%;
       text-align: center;
       .subtit {
        width: 100%;
        color: #666;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
       }
       .subtit::before {
        content: '';
        width: 30%;
        height: 1px;
        background: #707070;
        display: inline-block;
        margin-right: 20px;
       }
       .subtit::after {
        content: '';
        width: 30%;
        height: 1px;
        background: #707070;
        display: inline-block;
        margin-left: 20px;
       }
       .mobile {
        width: 73px;
        margin-top: 20px;
       }
    }
  }
  
</style>
