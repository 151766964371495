// 获取url中'?'符后的参数
export function getUrlParams(params) {
  let query = {}

  if(params.indexOf('?') !== -1) {
    let str = params.substr(params.indexOf('?') + 1, params.length)
    let strs = str.split("&")
    for (let i = 0; i < strs.length; i++) {
      query[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
    }
    return query
  }
}