<template>
  <router-view></router-view>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- <MobileLogin /> -->
  <!-- <protocal-info2 /> -->
  <!-- <result-info /> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import MobileLogin from './components/mobileLogin.vue'
// import ProtocalInfo from './components/protocalInfo.vue'
// import ProtocalInfo2 from './components/protocalInfo2.vue'
// import ResultInfo from './components/resultInfo.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld,
    // MobileLogin,
    // ProtocalInfo,
    // ProtocalInfo2,
    // ResultInfo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
