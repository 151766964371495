<template>
  <div>  
    <div class="container">
      <div class="title">
        <div class="t1">你好，</div>
        <div>欢迎登录海尔账号</div>
      </div>
      <div class="form-group">
        <div class="form-item">
          <input type="text" v-model="mobile" placeholder="请输入手机号">
        </div>
        <div class="form-item">
          <input v-model="verifyCode" type="text" maxlength="6" placeholder="请输入短信验证码">
          <button v-if="isShow" class="validate" @click="verification">获取验证码</button>
          <button v-else class="validate2">{{ second }}s</button>
        </div>
        <button class="login" @click="login">{{ type==='1'?"登录":"绑定手机号" }}</button>
      </div>
      <div v-if="type==='1'" class="agreements">
        <img v-if="sure===false" src="./../assets/normal.png" alt="" @click="editSure(true)">
        <img v-if="sure===true" src="./../assets/select.png" @click="editSure(false)">
        <!-- <input type="radio" v-model="sure" :checked="sure" @click="editSure"> -->
        <span class="info">已阅读并同意 <span class="agreement" @click="toProtocal">《海尔智能电视服务条款》</span><span class="agreement" @click="toProtocal2">《海尔电视个人信息保护政策》</span> </span>
      </div>
    </div>
  </div> 
</template>
<script>
import { Toast } from 'vant'
// import VConsole from 'vconsole'
import { sendVerifyCode, mobileLogin, socailBind } from '@/API/request'
 export default {
  data() {
    return {
      isShow: true,
      sure: false,
      second:60,
      timeInterval: null,
      mobile: '',
      verifyCode: '',
      deviceInfo: {},
      wechatInfo: {},
      type: '2'
    }
  },
  created() {
    // new VConsole()
    this.type = this.$route.query.type
    this.deviceInfo = JSON.parse(sessionStorage.getItem('deviceInfo'))
    this.mobile = sessionStorage.getItem('mobile') || ''
    this.verifyCode = sessionStorage.getItem('verifyCode') || ''
    if(this.type === '1') {
      window.TDAPP.onEvent('进入手机号登录页面')
    } else {
      window.TDAPP.onEvent('进入绑定手机号页面')
    }
  },
  methods: {
    editSure(val) {
      this.sure = val
    },
    verification() {
      window.TDAPP.onEvent('获取验证码')
      if(this.isShow) {
        this.second = 60
        this.isShow = false
        if (!this.timeInterval) {
          this.timeInterval = setInterval(() => {
            this.second --
            if (this.second === 0) {
              clearInterval(this.timeInterval)
              this.timeInterval = null
              this.isShow = true
            }
          }, 1000);
        }
      } else {
        console.log('验证码在请求中')
      }
      const param = { 
        "mobile": this.mobile,
        "deviceMAC": this.deviceInfo.deviceMAC,
         uuid: this.deviceInfo.uuid
      }
      sendVerifyCode(param).then(res => {
        if (res.errcode !== 0) {
          Toast(res.errmsg)
          clearInterval(this.timeInterval)
          this.timeInterval = null
          this.isShow = true
        }
      })
    },
    login() {
      let params = {}
      let http = mobileLogin
      if (this.type === '1') {
        window.TDAPP.onEvent('点击手机号登录')
        if (!this.sure) {
          Toast('请先阅读并同意服务条款')
          return
        }
        params = {
          mobile: this.mobile,
          verifyCode: this.verifyCode,
          uhome_app_version: this.deviceInfo.uhome_app_version,
          uhome_client_id: this.deviceInfo.uhome_client_id,
          source: '6',
          deviceMAC: this.deviceInfo.deviceMAC,
          wiredMac: this.deviceInfo.wiredMAC,
          uuid: this.deviceInfo.uuid,
          machineCode: this.deviceInfo.machineCode
        }
        http = mobileLogin
      } else {
        window.TDAPP.onEvent('点击手机号绑定')
        this.wechatInfo = JSON.parse(sessionStorage.getItem('wechatInfo'))
        params = {
          mobile: this.mobile,
          verifyCode: this.verifyCode,
          uhome_app_version: this.wechatInfo.uhome_app_version,
          uhome_client_id: this.wechatInfo.uhome_client_id,
          source: '7',
          deviceMAC: this.wechatInfo.deviceMAC,
          wiredMac: this.wechatInfo.wiredMac,
          openid: this.wechatInfo.openid,
          unionid: this.wechatInfo.unionid,
          socialAccessToken: this.wechatInfo.socialAccessToken,
          headImage: this.wechatInfo.headImage,
          uuid: this.deviceInfo.uuid,
          machineCode: this.deviceInfo.machineCode
        }
        http = socailBind
      }
       http(params).then(res=>{
        if (res.errcode === 0) {
          this.$router.push('/result')
        } else {
          Toast(res.errmsg)
        }
       })
    },
    toProtocal() {
      sessionStorage.setItem('mobile', this.mobile)
      sessionStorage.setItem('verifyCode', this.verifyCode)
      window.TDAPP.onEvent('查看服务条款')
      this.$router.push('/protocalInfo')
    },
    toProtocal2() {
      sessionStorage.setItem('mobile', this.mobile)
      sessionStorage.setItem('verifyCode', this.verifyCode)
      window.TDAPP.onEvent('查看信息政策')
      this.$router.push('/protocalInfo2')
    }
  }
 }
</script>
<style lang="scss" scoped>
  .container{
    width: 100%;
    padding: 40px 50px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .title {
    font-size: 49px;
    font-weight: 500;
    color: #3667FA;
    text-align: left;
    margin-left: 43px;
    font-family: PingFang SC-Medium, PingFang SC;
    .t1 {
      margin-bottom: 26px;
    }
  }
  .form-group{
    margin-top:86px ;
    text-align: center;
  }
  .form-item {
    width: 650px;
    height: 90px;
    background: #F8F8F8;
    border-radius: 45px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px;
    box-sizing: border-box;
    input {
      height: 80px;
      padding-left:20px ;
      border: none;
      background: none;
      font-size: 26px;
      box-sizing: border-box;
    }
    :input-placeholder{
      color: #C5C5C5; 
    }
    .validate {
      width: 186px;
      height: 61px;
      background: #FFFFFF;
      border-radius: 30px;
      border: none;
      color: #5E5E5E;
    }
    .validate2 {
      width: 186px;
      height: 61px;
      background: #397EF5;
      border-radius: 30px;
      border: none;
      color: #fff;
    }
   
  }
  .login{
    width: 495px;
    height: 78px;
    background: linear-gradient(155deg, #397EF5 0%, #95A4FF 100%);
    border: none;
    color: #FFFFFF;
    border-radius: 39px;
    font-size: 30px;
  }
  .agreements{
      position: absolute;
      bottom: 40px;
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      // width: 100%;
      padding-right: 40px;
      display: flex;
      align-items: flex-start;
      box-sizing: border-box;
      img {
        width: 22px;
        height: 22px;
        margin-top: 5px ;
        margin-right: 5px;
      }
      input {
        // margin: 2px 2px 0 0;
        // width: 18px;
        // height: 18px;
        border: #707070;
      }

      .info{
        text-align: left;
      }
      .agreement {
        color: #397EF5;
      }
    }
</style>